export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  deployUrl: 'Scripts/clientdist/',
  apiUrl: window.location.origin + '/api/',//'https://devapi.currencyxchanger.ca/api/',
  reportingApiUrl: 'http://qa.cvsapi.optionmatrix.net/api/clearview/',

  jsreportServerUrl: 'https://js-clearviewsys.jsreportonline.net',
  reCaptchaSiteKey: '6LekrmgoAAAAAD1U1jO9zGkCDG_kRPistdaqSz5A',
  pq_grid: {
    default_rPP: 50,
    dialog_rPP: 5
  },
  ssoLoginAppId: '616463734d23fadd7b9fb97a',
  apiBaseUrl: window.location.origin
};
